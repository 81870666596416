import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
@Injectable({
    providedIn: 'root'
})
export class ManagementService {
    baseUrl: string = environment.backend.baseURL;
    constructor(private http: HttpClient) { }
    fetchBaseData(body?) {
        let header = this.ignoreLoadingHesders();
        return this.http.get<any>(`${this.baseUrl}/api/management/baseData`, { ...{ params: body }, ...header });
    }
    stores(needMask = false, extra = {}) {
        let param = { params: extra };
        if (!needMask) {
            let header = this.ignoreLoadingHesders();
            param = { ...param, ...header};
        }
        return this.http.get<any>(`${this.baseUrl}/api/management/stores`, param);
    }

    levelOptions(needMask = false) {
        let param = {}
        if (!needMask) {
            param = this.ignoreLoadingHesders();
        }
        return this.http.get<any>(`${this.baseUrl}/api/management/levels`, param);
    }

    departmentOptions(needMask = false) {
        let param = {}
        if (!needMask) {
            param = this.ignoreLoadingHesders();
        }
        return this.http.get<any>(`${this.baseUrl}/api/management/departments`, param);
    }

    managerPositionOptions(needMask = false) {
        let param = {}
        if (!needMask) {
            param = this.ignoreLoadingHesders();
        }
        return this.http.get<any>(`${this.baseUrl}/api/management/managerPositions`, param);
    }

    roles(needMask = false) {
        let param = {}
        if (!needMask) {
            param = this.ignoreLoadingHesders();
        }
        return this.http.get<any>(`${this.baseUrl}/api/management/roles`, param);
    }

    updateRole(body?) {
        return this.http.put<any>(`${this.baseUrl}/api/management/role`, body);
    }

    addRole(body?) {
        return this.http.post<any>(`${this.baseUrl}/api/management/role`, body);
    }

    deleteRole(options?) {
        return this.http.delete<any>(`${this.baseUrl}/api/management/role`, options);
    }

    permissions(needMask = false) {
        let param = {}
        if (!needMask) {
            param = this.ignoreLoadingHesders();
        }
        return this.http.get<any>(`${this.baseUrl}/api/management/permissions`, param);
    }

    savePermissions(body) {
        return this.http.post<any>(`${this.baseUrl}/api/management/permissions`, body);
    }

    positions(needMask = false) {
        if(!needMask) {
            let param = this.ignoreLoadingHesders();
            return this.http.get<any>(`${this.baseUrl}/api/management/positions`, param);
        } else {
            return this.http.get<any>(`${this.baseUrl}/api/management/positions`);
        }
    }

    payments(needMask = false) {
        if (!needMask) {
            let param = this.ignoreLoadingHesders();
            return this.http.get<any>(`${this.baseUrl}/api/management/payments`, param);
        } else {
            return this.http.get<any>(`${this.baseUrl}/api/management/payments`);
        }
    }

    settings(needMask = false) {
        if (!needMask) {
            let param = this.ignoreLoadingHesders();
            return this.http.get<any>(`${this.baseUrl}/api/management/settings`, param);
        } else {
            return this.http.get<any>(`${this.baseUrl}/api/management/settings`);
        }
    }

    workSetting(needMask = false) {
        if (!needMask) {
            let param = this.ignoreLoadingHesders();
            return this.http.get<any>(`${this.baseUrl}/api/management/workHoursSetting`, param);
        } else {
            return this.http.get<any>(`${this.baseUrl}/api/management/workHoursSetting`);
        }
    }

    configs(needMask = false) {
        if (!needMask) {
            let param = this.ignoreLoadingHesders();
            return this.http.get<any>(`${this.baseUrl}/api/management/configs`, param);
        } else {
            return this.http.get<any>(`${this.baseUrl}/api/management/configs`);
        }
    }

    saveConfigs(body) {
        return this.http.post<any>(`${this.baseUrl}/api/management/configs`, body);
    }

    staffs(needMask = false, body = {}) {
        let param = { params: body };
        if (!needMask) {
            let header = this.ignoreLoadingHesders();
            param = { ...param, ...header };
        }
        return this.http.get<any>(`${this.baseUrl}/api/management/staffs`, param);
    }

    purchaseStaffs(needMask = false, body = {}) {
        let param = { params: body };
        if (!needMask) {
            let header = this.ignoreLoadingHesders();
            param = { ...param, ...header };
        }
        return this.http.get<any>(`${this.baseUrl}/api/management/purchaseStaffs`, param);
    }

    inventoryStaffs(needMask = false, body = {}) {
        let param = { params: body };
        if (!needMask) {
            let header = this.ignoreLoadingHesders();
            param = { ...param, ...header };
        }
        return this.http.get<any>(`${this.baseUrl}/api/management/inventoryStaffs`, param);
    }

    saveStaffRole(body?) {
        return this.http.post<any>(`${this.baseUrl}/api/management/staffRole`, body);
    }

    brands(needMask = false) {
        if (!needMask) {
            let param = this.ignoreLoadingHesders();
            return this.http.get<any>(`${this.baseUrl}/api/management/brands`, param);
        } else {
            return this.http.get<any>(`${this.baseUrl}/api/management/brands`);
        }
    }

    materialCates(needMask = false) {
        if (!needMask) {
            let param = this.ignoreLoadingHesders();
            return this.http.get<any>(`${this.baseUrl}/api/management/materialCates`, param);
        } else {
            return this.http.get<any>(`${this.baseUrl}/api/management/materialCates`);
        }
    }

    banks(needMask = false) {
        if (!needMask) {
            let param = this.ignoreLoadingHesders();
            return this.http.get<any>(`${this.baseUrl}/api/management/banks`, param);
        } else {
            return this.http.get<any>(`${this.baseUrl}/api/management/banks`);
        }
    }

    expenses(needMask = false) {
        if (!needMask) {
            let param = this.ignoreLoadingHesders();
            return this.http.get<any>(`${this.baseUrl}/api/management/expenses`, param);
        } else {
            return this.http.get<any>(`${this.baseUrl}/api/management/expenses`);
        }
    }

    jobs(needMask = false) {
        if (!needMask) {
            let param = this.ignoreLoadingHesders();
            return this.http.get<any>(`${this.baseUrl}/api/management/jobs`, param);
        } else {
            return this.http.get<any>(`${this.baseUrl}/api/management/jobs`);
        }
    }

    source(needMask = false) {
        if (!needMask) {
            let param = this.ignoreLoadingHesders();
            return this.http.get<any>(`${this.baseUrl}/api/management/source`, param);
        } else {
            return this.http.get<any>(`${this.baseUrl}/api/management/source`);
        }
    }

    memberLevelSetting(needMask = false) {
        if (!needMask) {
            let param = this.ignoreLoadingHesders();
            return this.http.get<any>(`${this.baseUrl}/api/management/memberLevelSetting`, param);
        } else {
            return this.http.get<any>(`${this.baseUrl}/api/management/memberLevelSetting`);
        }
    }

    workerCostItem(needMask = false, body = {}) {
        let param = { params: body };
        if (!needMask) {
            let header = this.ignoreLoadingHesders();
            param = { ...param, ...header };
            return this.http.get<any>(`${this.baseUrl}/api/management/workerCostItem`, param);
        } else {
            return this.http.get<any>(`${this.baseUrl}/api/management/workerCostItem`);
        }
    }

    officialAccount(needMask = false) {
        if (!needMask) {
            let param = this.ignoreLoadingHesders();
            return this.http.get<any>(`${this.baseUrl}/api/management/officialAccount`, param);
        } else {
            return this.http.get<any>(`${this.baseUrl}/api/management/officialAccount`);
        }
    }

    shopOfficialAccount(needMask = false) {
        if (!needMask) {
            let param = this.ignoreLoadingHesders();
            return this.http.get<any>(`${this.baseUrl}/api/management/shopOfficialAccount`, param);
        } else {
            return this.http.get<any>(`${this.baseUrl}/api/management/shopOfficialAccount`);
        }
    }

    taiwanBanks(needMask = false) {
        if (!needMask) {
            let param = this.ignoreLoadingHesders();
            return this.http.get<any>(`${this.baseUrl}/api/management/taiwanBanks`, param);
        } else {
            return this.http.get<any>(`${this.baseUrl}/api/management/taiwanBanks`);
        }
    }

    //會員可推播訊息的oa
    membersOA(body?, needMask = false) {
        if (!needMask) {
            let header = this.ignoreLoadingHesders();
            return this.http.get<any>(`${this.baseUrl}/api/management/membersOA`, { ...{ params: body }, ...header });
        } else {
            return this.http.get<any>(`${this.baseUrl}/api/management/membersOA`, { ...{ params: body } });
        }
    }

    //更新記錄
    changeLogs(body?, needMask = false) {
        if (!needMask) {
            let header = this.ignoreLoadingHesders();
            return this.http.get<any>(`${this.baseUrl}/api/management/changeLogs`, { ...{ params: body }, ...header });
        } else {
            return this.http.get<any>(`${this.baseUrl}/api/management/changeLogs`, { ...{ params: body } });
        }
    }

    sortRole(body?) {
        return this.http.post<any>(`${this.baseUrl}/api/management/roleSort`, body);
    }

    terms(body?) {
        return this.http.get<any>(`${this.baseUrl}/api/management/terms`, { ...{ params: body } });
    }

    questions(body?) {
        return this.http.get<any>(`${this.baseUrl}/api/management/questions`, { ...{ params: body } });
    }
    fetchQuestion(id) {
        return this.http.get<any>(`${this.baseUrl}/api/management/question/${id}`);
    }
    saveQuestion(body?) {
        return this.http.post<any>(`${this.baseUrl}/api/management/question`, body);
    }
    deleteQustion(options?) {
        return this.http.delete<any>(`${this.baseUrl}/api/management/question`, options);
    }
    saveTerms(body?) {
        return this.http.post<any>(`${this.baseUrl}/api/management/terms`, body);
    }

    deleteTerms(options?) {
        return this.http.delete<any>(`${this.baseUrl}/api/management/terms`, options);
    }

    previewTerms(storid) {
        return this.http.get<any>(`${this.baseUrl}/api/management/previewTerms/${storid}`);
    }

    saveCustomerBank(body?) {
        return this.http.post<any>(`${this.baseUrl}/api/management/bank`, body);
    }

    private ignoreLoadingHesders() {
        return { headers: { 'ignoreloading': '1' } };
    }
}
